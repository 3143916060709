<template>
  <div class="main">
    <slot />
  </div>
  <loading v-show="isLoading" />
  <error-popup v-if="showErrorPopup" @close="closeError">
    <pre>{{ errorMsg }}</pre>
  </error-popup>
  <error-token-popup v-if="showErrorTokenPopup" @close="closeWebView">
    <!-- <pre>{{ errorMsg }}</pre> -->
  </error-token-popup>
</template>

<script>
import { mapWritableState } from "pinia";
import { errorPopup, errorTokenPopup } from "~/components/errorMsg";
import { loading } from "~/components/common";
import { useCommonStore } from "~/src/stores/common";
import { postMessage } from "~/src/utils";

export default {
  name: "Layout",
  components: {
    loading,
    errorPopup,
    errorTokenPopup,
  },
  data() {
    return {
      pages: ["/home/"],
      lang: "en",
    };
  },
  created() {
    this.lang = this.$i18n.locale;
  },
  methods: {
    closeError() {
      this.showErrorPopup = false;
    },
    closeWebView() {
      if (process.client) {
        // window.ReactNativeWebView?.postMessage(
        //   JSON.stringify({ type: "NAVIGATE_BACK" })
        // );
        postMessage({
          type: "NAVIGATE_BACK",
        })
          .then(() => {})
          .catch((error) => {
            console.log("closeWebView error: ", error);
            this.showErrorPopup = true;
          });
      }
    },
  },
  setup() {},
  computed: {
    ...mapWritableState(useCommonStore, ["showErrorPopup"]),

    content() {
      const lang = this.$i18n.locale;
      console.log(lang);
      if (!this.pageContent || !this.pageContent[lang]) {
        return [];
      }

      return this.pageContent[lang];
    },
    ...mapWritableState(useCommonStore, [
      "showErrorTokenPopup",
      "showErrorPopup",
      "errorMsg",
      "isLoading",
    ]),
  },
};
</script>
