<template>
  <div class="loading">
    <div class="ly--content--container">
      <img src="/images/common/LoadingIcon.gif" alt="" class="loading__img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;

  &__img {
    width: rem(120);
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    bottom: 0;
    right: 0;
  }
}
</style>
